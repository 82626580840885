<template>
  <div class="flex flex-col space-y-2">
    <span class="text-space-dark-blue font-bold">{{label}}</span>
    <input
      type="file"
      :accept="accept"
      @change="onFileChange"
      v-if="isEditable">
    <img v-lazy="image" :key="getKey" v-if="image" width="100" height="100"  :class="imageClass" >
  </div>
</template>

<script>
export default {
  props: {
    isEditable: Boolean,
    label: String,
    accept: {
      type: String,
      default: '*'
    },
    value: {
      type: [String, File],
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  data() {
    return {
      image: undefined
    };
  },
  computed: {
    getKey() {
      return Math.random();
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.$emit('input', file);
    }
  },
  beforeDestroy() {
    if (this.value instanceof File) {
      URL.revokeObjectURL(this.image);
    }
    this.image = undefined;
  },
  mounted() {
    if (this.value === undefined) return;
    if (this.value instanceof File) {
      this.image = URL.createObjectURL(this.value);
    } else {
      this.image = this.value;
    }
  },
  watch: {
    value: function (value) {
      if (value === undefined) return;
      if (value instanceof File) {
        this.image = URL.createObjectURL(value);
      } else {
        this.image = value;
      }
    }
  }
};
</script>
