<template>
  <HomeLayout content-classname="mx-6 md:items-center">
    <template #content>
      <div class="flex flex-col space-y-4 bg-white rounded-xl p-8 md:w-3/5">
        <h1 class="text-2xl font-bold text-space-green font-raleway-extra-bold">{{ $t('profileForm.title') }}</h1>
        <span class="error-box text-xs text-space-darker-red" v-if="errors.formError">{{ errors.formError }}</span>
        <div class="flex flex-col space-y-4" v-if="userCurrentData">
          <div class="flex flex-col space-y-4" v-if="!isEditingPassword">
            <TextFormInput
              :disabled="!isEditingUserInfo"
              type="text"
              :value="userFormData.pseudo"
              field="pseudo"
              :errors="errors"
              :label="$t('registrationForm.username')"
              @input="onFieldChanged('pseudo', $event)"
            />
            <TextFormInput
              :disabled="!isEditingUserInfo"
              type="email"
              :value="userFormData.email"
              field="email"
              :errors="errors"
              :label="$t('registrationForm.email')"
              @input="onFieldChanged('email', $event)"
            />
            <FileFormInput
              :value="userFormData.avatar"
              :is-editable="isEditingUserInfo"
              :label="$t('profileForm.avatar')"
              @input="onFieldChanged('avatar', $event)"
              accept="image/svg+xml,image/png,image/jpeg,image/jpg"
              imageClass="h-12 w-12 rounded-full object-cover object-center"
            />
          </div>
          <div class="flex flex-col space-y-4" v-if="isEditingPassword">
            <TextFormInput
              type="password"
              :value="passwordFormData.oldPassword"
              field="oldPassword"
              :errors="errors"
              :label="$t('profileForm.oldPassword')"
              @input="onPasswordFormFieldChanged('oldPassword', $event)"
            />
            <TextFormInput
              type="password"
              :value="passwordFormData.password"
              field="password"
              :errors="errors"
              :label="$t('registrationForm.password')"
              @input="onPasswordFormFieldChanged('password', $event)"
            />
            <TextFormInput
              type="password"
              :value="passwordFormData.confirmPassword"
              field="confirmPassword"
              :errors="errors"
              :label="$t('registrationForm.confirmPassword')"
              @input="onPasswordFormFieldChanged('confirmPassword', $event)"
            />
          </div>

          <div class="w-full md-w-3/4 flex flex-col items-center md:flex-row w-full space-y-2 md:space-y-0 md:space-x-2">
            <PrimaryButton
              classes="w-fit-content"
              v-if="isEditingUserInfo || isEditingPassword"
              :bg-color="'bg-space-dark-blue'"
              :text="$t('common.cancel')"
              @click="handleCancel"
            />
            <PrimaryButton
              v-if="!isEditingPassword"
              classes="w-fit-content"
              :bg-color="'bg-space-dark-blue'"
              :text="$t(isEditingUserInfo ? 'common.validate': 'profileForm.editUserInfo')"
              @click="handleIsEditingUserInfoButton"
            />
            <PrimaryButton
              v-if="!isEditingUserInfo && userCurrentData.createdFrom === 'email'"
              classes="w-fit-content"
              :bg-color="'bg-space-dark-blue'"
              :text="$t(isEditingPassword ? 'common.validate': 'profileForm.editUserPassword')"
              @click="handleIsEditingPasswordButton"
            />
          </div>
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layouts/HomeLayout.vue";
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import UserService from "@/services/userService";
import FileFormInput from "@/components/File/FileFormInput.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import FormService from "@/services/formService";

export default {
  name: "Profile",
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    //next tick
    this.$nextTick(async () => {
      this.userCurrentData = await UserService.getUser(this.user.id);
      this.userFormData = {
        email: this.userCurrentData.email,
        pseudo: this.userCurrentData.pseudo,
        avatar: this.userCurrentData.avatar,
      };
    });
  },
  data() {
    return {
      userCurrentData: null,
      isEditingUserInfo: false,
      isEditingPassword: false,
      userFormData: {},
      errors: {},
      passwordFormData: {
        oldPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
      },
    };
  },
  methods: {
    onFieldChanged(field, value){
      this.userFormData = {
        ...this.userFormData,
        [field]: value,
      };
      this.errors[field] = undefined;
    },
    onPasswordFormFieldChanged(field, value){
      this.passwordFormData = {
        ...this.passwordFormData,
        [field]: value,
      };
      this.errors[field] = undefined;
    },
    handleIsEditingUserInfoButton(){
      if (this.isEditingUserInfo){
        this.submit();
      } else {
        this.isEditingUserInfo = true;
      }
    },
    handleIsEditingPasswordButton(){
      if (this.isEditingPassword){
        this.submit();
      } else {
        this.isEditingPassword = true;
      }
    },
    handleCancel(){
      this.isEditingUserInfo = false;
      this.isEditingPassword = false;
      this.passwordFormData = {};
      this.userFormData = {
        email: this.userCurrentData.email,
        pseudo: this.userCurrentData.pseudo,
        avatar: this.userCurrentData.avatar,
      };
    },
    submit(){
      const formData = new FormData();
      this.errors = {};
      const errors = this.isEditingUserInfo ? UserService.validateUpdateProfileForm(this.userFormData) : UserService.validateUpdatePasswordForm(this.passwordFormData);
      if (FormService.hasErrors(errors)){
        this.errors = errors;
        return;
      }
      if (this.isEditingUserInfo){
        formData.append('email', this.userFormData.email);
        formData.append('pseudo', this.userFormData.pseudo);
        //if avatar is changed then append the file
        if (this.userFormData.avatar instanceof File){
          formData.append('avatar', this.userFormData.avatar);
        }
      } else {
        formData.append('oldPassword', this.passwordFormData.oldPassword);
        formData.append('password', this.passwordFormData.password);
        formData.append('confirmPassword', this.passwordFormData.confirmPassword);
      }

      UserService.updateUserProfile(formData)
        .then(() => {
          this.isEditingUserInfo = false;
          this.isEditingPassword = false;
        })
        .catch((error) => {
          this.errors = {
            formError: ['email_already_taken', 'pseudo_already_exists', 'old_password_incorrect'].includes(error.response.data.code) ? this.$t(`common.errors.${error.response.data.code}`) : this.$t('common.errors.globalError'),
          };
        });
    },
  },
  components: {PrimaryButton, FileFormInput, TextFormInput, HomeLayout}
};
</script>

<style scoped>

</style>
